<template>



<div
        :style="{ backgroundImage: `url(${$api}/uploads/${blogPost.featuredImage})` }"
           class="villa-card--image-container md:h-96 h-80 bg-cover bg-center flex flex-col relative w-full bg-white text-black"
        >
        
        </div>


    <div 
     
      class="max-w-6xl mx-auto container leading-7 text-center bg-white text-sm text-black m-10"
    >


      <h1 class="text-2xl text-gray-800 uppercase ">  {{blogPost.title}}     </h1>
      <p   v-html="blogPost.content" class="md:px-40 px-6 text-justify text-gray-800 text-sm text-black leading-7  pt-8">
        
      </p>
    </div>

    <div class="w-full max-w-6xl mx-auto  mx-auto bg-white mt-8 md:mt-18 mb-20">
   
    </div>
</template>



<script>
 
export default {
  name: "TalesSingle",

  data() {
    return {
      
      blogPost: [],
    };
  },
  methods: {
    async getSingleBlogPost() {
      try {
        const res = await this.$axios.get(
          `${this.$api_v1}/blog/${this.$route.params.slug}`
        );

        if (res.status == 200) {
          this.blogPost = res.data.post;
        }
      } catch (e) {
        this.$errorHandler(e);
      }
    },
  },
  mounted() {
    this.getSingleBlogPost();
 
  
 
  },
  components: { },
};
</script>